export default {
    home_page: ' Página Inicial',
    failed: 'Falha',
    success: 'Sucesso',
    welcome_message: 'Bem vindo de volta',
    user: 'Utilizador',
    users: 'Utilizadores',
    movement: 'Movimento',
    movements: 'Movimentos',
    inventory: ' Inventário',
    product: 'Artigo',
    products: 'Artigos',
    locations: 'Localizações',
    location: 'Localização',
    location_code: 'Código de Local',
    category: 'Família',
    categories: 'Famílias',
    logout: 'Sair',
    quantity: 'Quantidade',
    type: 'Tipo',
    date: 'Data',
    staff: 'Colaborador',
    movement_manager: 'Gestor de Movimentos',
    entry: 'Entrada',
    exit: 'Saída',
    confirm: 'Confirmar',
    product_code: 'Código de Produto',
    inventory_manager: 'Gestor de Inventário',
    records_per_page: 'Registos por página',
    product_manager: 'Gestor de Artigos',
    value: 'Valor',
    description: 'Descrição',
    main_menu: 'Menu Principal',
    code: 'Código Único',
    username: 'Nome de utilizador',
    user_type: 'Tipo de Utilizador',
    cancel: 'Cancelar',
    save: 'Guardar',
    yes: 'Sim',
    no: 'Não',
    attention: 'Atenção',
    unit: 'Unidade',
    dimensions: 'Dimensões',
    size: 'Tamanho',
    product_code: 'Código de Artigo',
    location_code: 'Código de Localização',
    inventory_code: 'Código de Inventário',
    qr_code: 'Código QR',
    colour: 'Cor',
    reference: 'Referência',
    purchase: 'Compra',
    cost: 'Custo',
}
