import { boot } from 'quasar/wrappers';
import { createClient } from '@supabase/supabase-js'

const supabase = createClient('https://alufercosta.alpha64.app', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICJyb2xlIjogImFub24iLAogICJpc3MiOiAic3VwYWJhc2UiLAogICJpYXQiOiAxNzA3MTc3NjAwLAogICJleHAiOiAxODY1MDMwNDAwCn0.pznuTy-lRhKnEyVUC3k2ChopljxECCtYkijEzdCxntI');

export default boot(({ app }) => {
  app.config.globalProperties.$supabase = supabase;
});

export { supabase };
